<template>
    <v-main>
        <div class="main-content">
            <router-view> </router-view>
        </div>
    </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'RoleAdmins',
        computed: {
        ...mapGetters({
            roles: 'roles/getRole'
        }),
    },
        mounted() {
            if (this.roles.length === 0) {
            this.$store.dispatch('roles/awaitGetRoles');
        }
        }
    }
</script>

<style lang="scss" scoped>

</style>